<template>
  <b-card>
    <div
      v-if="loader"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <validation-observer
      v-else
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Mail contact address"
          >
            <b-form-group
              label="Mail contact address"
              label-for="mail_contact_address"
            >
              <b-form-input
                id="mail_contact_address"
                v-model="socialForm.mail_contact_address"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Mail contact address"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="12">
          <div
            v-for="(item, i) in socialForm.fields"
            :key="i"
            class="border-group mt-100"
          >
            <template>
              <b-button
                variant="error"
                @click="deleteField(i)"
              >
                <b-icon
                  icon="x-circle"
                  variant="danger"
                  class="p-abslute"
                  scale="2"
                />
              </b-button>
            </template>
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="en_name"
                >
                  <b-form-group
                    label="name (en)"
                    :label-for="'en_name'+i"
                  >
                    <b-form-input
                      :id="'en_name'+i"
                      v-model="item.en_name"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Name (En)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="ar_name"
                >
                  <b-form-group
                    label="name (Ar)"
                    :label-for="'ar_name'+i"
                  >
                    <b-form-input
                      :id="'ar_name'+i"
                      v-model="item.ar_name"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="name (Ar)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="Value"
                >
                  <b-form-group
                    label="Value"
                    :label-for="'value'+i"
                  >
                    <b-form-input
                      :id="'value'+i"
                      v-model="item.value"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="value"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  name="icon"
                >
                  <b-form-group
                    label="Icon"
                    :label-for="'icon'+i"
                  >
                    <b-form-input
                      :id="'icon'+i"
                      v-model="item.icon"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Icon"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  name="type"
                >
                  <b-form-group
                    label="type"
                    :label-for="'type'+i"
                  >
                    <b-select
                      :id="'type'+i"
                      v-model="item.type"
                      :options="types"
                      :filterable="false"
                      label="type"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col md="6">
          <b-button
            variant="primary"
            size="lg"
            class="mb-75 mt-75 w-100"
            @click="addField()"
          >
            add Field
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="socialForm.en_color_logo"
                  height="80"
                  width="300"
                />
              </b-link>
              <!--/ image -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectEnImg()"
              >
                Upload Color Logo (En)
              </b-button>
              <b-form-file
                ref="refEnImg"
                accept=".jpg, .png, .jpeg"
                :hidden="true"
                plain
                @change="changeEnImage($event)"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="socialForm.ar_color_logo"
                  height="80"
                  width="300"
                />
              </b-link>
              <!--/ image -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectArImg()"
              >
                Upload Color Logo (Ar)
              </b-button>
              <b-form-file
                ref="refArImg"
                accept=".jpg, .png, .jpeg"
                :hidden="true"
                plain
                @change="changeArImage($event)"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>

        <b-col cols="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="socialForm.en_white_logo"
                  height="80"
                  width="300"
                />
              </b-link>
              <!--/ image -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectEnWLogo()"
              >
                Upload White Logo (En)
              </b-button>
              <b-form-file
                ref="refEnWLogo"
                accept=".jpg, .png, .jpeg"
                :hidden="true"
                plain
                @change="changeEnWLogo($event)"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="socialForm.ar_white_logo"
                  height="80"
                  width="300"
                />
              </b-link>
              <!--/ image -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectArWLogo()"
              >
                Upload White Logo (Ar)
              </b-button>
              <b-form-file
                ref="refArWLogo"
                accept=".jpg, .png, .jpeg"
                :hidden="true"
                plain
                @change="changeArWLogo($event)"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            @click="addSocial()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  data() {
    return {
      required,
      id: this.$store.state.generalIds.id,
      loader: false,
      file: '',
      ar_color_logo: '',
      en_color_logo: '',
      ar_white_logo: '',
      en_white_logo: '',
      types: [
        'socials', 'contact',
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const socialForm = ref({
      ar_color_logo: null,
      en_color_logo: null,
      ar_white_logo: null,
      en_white_logo: null,

    })
    // eslint-disable-next-line camelcase
    const file = ref('')

    return {
      getValidationState,
      socialForm,
      file,
    }
  },
  mounted() {
    this.showSocial()
  },
  methods: {
    addField() {
      this.socialForm.fields.push(
        {
          icon: '',
          en_name: '',
          ar_name: '',
          value: '',
        },
      )
    },
    deleteField(i) {
      this.socialForm.fields.splice(i, 1)
    },
    selectArWLogo() {
      this.$refs.refArWLogo.$el.click()
    },
    selectEnWLogo() {
      this.$refs.refEnWLogo.$el.click()
    },
    selectArImg() {
      this.$refs.refArImg.$el.click()
    },
    selectEnImg() {
      this.$refs.refEnImg.$el.click()
    },
    changeArWLogo(e) {
      // eslint-disable-next-line prefer-destructuring
      this.ar_white_logo = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.socialForm.ar_white_logo = er.target.result
          }

          // eslint-disable-next-line prefer-destructuring
          this.ar_white_logo = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    changeEnWLogo(e) {
      // eslint-disable-next-line prefer-destructuring
      this.en_white_logo = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.socialForm.en_white_logo = er.target.result
          }

          // eslint-disable-next-line prefer-destructuring
          this.en_white_logo = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    changeEnImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.en_color_logo = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.socialForm.en_color_logo = er.target.result
          }

          // eslint-disable-next-line prefer-destructuring
          this.en_color_logo = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    changeArImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.ar_color_logo = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.socialForm.ar_color_logo = er.target.result
          }

          // eslint-disable-next-line prefer-destructuring
          this.ar_color_logo = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },
    showSocial() {
      this.loader = true

      axios.get('admin/socials').then(res => {
        this.loader = false

        this.socialForm = res.data.data
      })
    },
    addSocial() {
      const formData = new FormData()
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in this.socialForm) {
        // eslint-disable-next-line eqeqeq
        // if (key !== 'ar_color_logo' || key !== 'en_color_logo' || key !== 'en_white_logo' || key !== 'ar_white_logo' || key != 'fields') {
        //   formData.append(key, this.socialForm[key])
        // }
        if (key === 'fields') {
          this.socialForm.fields.forEach((item, i) => {
            formData.append(`${key}[${i}][icon]`, item.icon)
            formData.append(`${key}[${i}][en_name]`, item.en_name)
            formData.append(`${key}[${i}][ar_name]`, item.ar_name)
            formData.append(`${key}[${i}][value]`, item.value)
            formData.append(`${key}[${i}][type]`, item.type)
          })
        }
      }
      formData.delete('ar_color_logo')
      formData.delete('en_color_logo')
      formData.delete('ar_white_logo')
      formData.delete('en_white_logo')
      if (this.ar_white_logo) {
        formData.append('ar_white_logo', this.ar_white_logo)
      }
      if (this.en_white_logo) {
        formData.append('en_white_logo', this.en_white_logo)
      }
      if (this.ar_color_logo) {
        formData.append('ar_color_logo', this.ar_color_logo)
      }
      if (this.en_color_logo) {
        formData.append('en_color_logo', this.en_color_logo)
      }
      formData.append('mail_contact_address', this.socialForm.mail_contact_address)

      this.loader = true
      axios.post('admin/socials', formData).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.showSocial()
          this.$toasted.show('Updated Successfully', {
            position: 'top-center',
            duration: 3000,
          })
        }
      }).catch(error => {
        if (error.response.status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              link: 'Somthing Went Wrong',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .border-group{
    border: 1px solid #c8c8c8;
    margin: 1px;
    padding: 10px;
    border-radius: 15px;
    position: relative;
    .p-abslute{
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
  .mt-100{
    margin-top: 25px;
  }
  </style>
